// @owner ClientPlatform

import React, {useEffect} from 'react';
import {StyledParagraph, StyledRow} from '../../../signin/commonSigninStyles';
import Cookies from 'js-cookie';
import {returnValidRedirectUrl, userDidSignIn} from '../../../helpers/signupHelpers';

export const SsoRedirect = () => {
  useEffect(() => {
    const ssoBaseUrl = `${location.protocol}//${location.host}`;
    const redirectUrl = returnValidRedirectUrl(Cookies.get('front.redirect_to') || '');
    Cookies.remove('front.redirect_to');
    userDidSignIn(redirectUrl, ssoBaseUrl);
  }, []);

  return (
    <StyledRow>
      <StyledParagraph>Sign in successful. You are being redirected to the app.</StyledParagraph>
    </StyledRow>
  );
};
