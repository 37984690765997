// @owner ClientPlatform

import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import {SsoPage} from '../../helpers/ssoPage';
import {StyledParagraph, StyledRow} from '../../../signin/commonSigninStyles';
import {styled} from 'styled-components';
import {SigninButton} from '../../../signin/components/signinButton/signinButton';
import Cookies from 'js-cookie';
import {openSigninPopup, pathToRedirectTo} from '../../../helpers/signupHelpers';
import {platform} from '../../../helpers/userAgent';
import {navigateInContext} from '../../../common/location/locationHelpers';

const StyledHeaderText = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
`;

const StyledErrorMessage = styled.div`
  width: 100%;
  font-size: 18px;
  color: #ff0c0c;
  line-height: 1.5;
  text-align: center;
  padding: 6px 5%;
`;

const DEFAULT_COMPANY_NAME = 'your company';
export const MISSING_ACCOUNT_ERROR = 'missing_acct';

interface SsoSigninProps {
  mode: SsoPage;
}

export const SsoSignin: FC<PropsWithChildren<SsoSigninProps>> = ({mode}) => {
  const companyDomain = location.host;
  const searchParams = new URLSearchParams(location.search);
  const companyName = searchParams.get('company') || DEFAULT_COMPANY_NAME;
  const errorMsg = searchParams.get('sso_error') || '';
  const isMissingAccountError = searchParams.get('sso_error_type') === MISSING_ACCOUNT_ERROR;

  return (
    <div>
      <StyledRow>
        <StyledHeaderText>{findHeaderText(mode, companyDomain)}</StyledHeaderText>
      </StyledRow>

      {maybeRenderErrorMessage(errorMsg, isMissingAccountError)}

      <div>
        <StyledRow>
          <StyledParagraph>{findSignInText(mode, companyName)}</StyledParagraph>
        </StyledRow>

        <StyledRow>
          <SigninButton type="submit" onClick={signIn}>
            Sign in with SSO
          </SigninButton>
        </StyledRow>
      </div>
    </div>
  );
};

function signIn() {
  Cookies.set('front.redirect_to', pathToRedirectTo());

  const loginUrl = `${location.protocol}//${location.host}/sso/login`;

  if (platform.web) {
    // Allows installed PWAs to open the login page in the app context.
    navigateInContext(loginUrl);
  } else {
    openSigninPopup(loginUrl, 'Sign in to Front', {height: 530, width: 650});
  }
}

function buildErrorMessage(errorMsg: string, isMissingAccountError: boolean) {
  if (isMissingAccountError) {
    return `Single Sign On failed (${errorMsg}). Please contact your admin to set up an account in Front.`;
  }

  return `Single Sign On failed (${errorMsg}). Please contact team@front.com for more information.`;
}

function maybeRenderErrorMessage(errorMsg: string, isMissingAccountError: boolean) {
  if (!errorMsg) {
    return null;
  }

  return (
    <StyledRow>
      <StyledErrorMessage>{buildErrorMessage(errorMsg, isMissingAccountError)}</StyledErrorMessage>
    </StyledRow>
  );
}

function findSignInText(mode: SsoPage, companyName: string) {
  switch (mode) {
    case SsoPage.LOGIN:
      return 'This company requires you to sign in using Single Sign On.';
    case SsoPage.NEW_LOGIN:
      return `Join ${companyName} by signing up with Single Sign On (SSO)`;
    default:
      return '';
  }
}

function findHeaderText(mode: SsoPage, companyDomain: string) {
  switch (mode) {
    case SsoPage.LOGIN:
      return `Sign in to ${companyDomain}`;
    case SsoPage.NEW_LOGIN:
      return 'Welcome to Front';
    default:
      return '';
  }
}
