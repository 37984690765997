// @owner ClientPlatform

import React, {useEffect} from 'react';
import {SsoPage} from './helpers/ssoPage';
import {SsoSignin} from './pages/ssoSignin/ssoSignin';
import {SsoRedirect} from './pages/ssoRedirect/ssoRedirect';
import {StyledHeader, StyledPageWrapper, StyledWrapper} from '../signin/signin';
import {SigninGlobalStyles} from '../signin/signinGlobalStyles';
import {signalAppLoaded, findSignupWindow} from '../helpers/signupHelpers';

export const Sso = () => {
  const searchParams = new URLSearchParams(location.search);
  const hasErrorMessage = Boolean(searchParams.get('sso_error'));
  const mode = findMode(hasErrorMessage);

  useEffect(() => {
    signalAppLoaded();
  }, []);

  useEffect(() => {
    const window = findSignupWindow();
    if (hasErrorMessage && window.windowBridge) {
      window.windowBridge.showPopup();
    }
  }, [hasErrorMessage]);

  return (
    <StyledWrapper className="signin">
      <SigninGlobalStyles />
      <StyledPageWrapper id="container" className="login">
        <StyledHeader />
        {renderContent(mode)}
      </StyledPageWrapper>
    </StyledWrapper>
  );
};

function findMode(hasErrorMessage: boolean) {
  const searchParams = new URLSearchParams(location.search);
  const isNewTeammate = Boolean(searchParams.get('newTeammate'));
  const isCallback = /\/callback$/.test(location.pathname);

  if (isCallback && !hasErrorMessage) {
    return SsoPage.CALLBACK;
  }

  return isNewTeammate ? SsoPage.NEW_LOGIN : SsoPage.LOGIN;
}

function renderContent(mode: SsoPage) {
  switch (mode) {
    case SsoPage.LOGIN:
    case SsoPage.NEW_LOGIN:
      return <SsoSignin mode={mode} />;
    case SsoPage.CALLBACK:
      return <SsoRedirect />;
    default:
      return null;
  }
}
